<template>
  <v-dialog
    max-width="400"
    :value="show"
    @input="close"
  >
    <v-card>
      <v-card-title class="headline">
        <slot name="title" />
      </v-card-title>

      <v-card-text>
        <slot name="body" />
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
          v-if="cancelable"
          color="darken-1"
          text
          @click="close"
        >
          Cancelar
        </v-btn>

        <v-btn
          text
          :color="color"
          @click="submit"
        >
          <slot name="action" />
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'Modal',

  props: {
    show: {
      type: Boolean,
      required: true,
    },

    type: {
      type: String,
      default: 'error',
    },

    cancelable: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    color() {
      return `${this.type} darken-1`;
    },
  },

  methods: {
    close() {
      this.$emit('update:show', false);
    },

    submit() {
      this.$emit('submit');
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
