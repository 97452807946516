<template>
  <section>
    <v-row>
      <v-col>
        <v-breadcrumbs :items="breadcrumbs" class="ma-0 pa-0" />
      </v-col>
    </v-row>

    <v-row>
      <v-col class="d-flex justify-space-between flex-wrap">
        <h1> {{ discount.code | uppercase }} </h1>

        <span>
          <v-btn class="mr-3" color="warning" :to="{ name: 'discount-form', params: { uuid } }">
            <v-icon left>mdi-pencil</v-icon>
            Editar
          </v-btn>

          <v-btn color="error" @click="handleDeleteModal">
            <v-icon left>mdi-delete</v-icon>
            Eliminar
          </v-btn>
        </span>
      </v-col>
    </v-row>

    <v-card>
      <v-container>
        <v-row>
          <v-col cols="12" sm="6"> <b>Código:</b> {{ discount.code }} </v-col>

          <v-col cols="12" sm="6"> <b>Descuento:</b> {{ discount.discount }}% </v-col>

          <v-col cols="12" sm="6"> <b>Fecha inicio:</b> {{ discount.start_date }} </v-col>

          <v-col cols="12" sm="6"> <b>Fecha fin:</b> {{ discount.end_date }} </v-col>

          <v-col cols="12" sm="6"> <b>Aplicaciones:</b> {{ discount.num_uses }} </v-col>
        </v-row>
      </v-container>
    </v-card>

    <Modal :show.sync="showDeleteModal" @submit="remove">
      <template #title>Eliminar</template>

      <template #body>
        ¿Deseas eliminar el descuento
        <b>{{ discount.code | uppercase }}</b>
        ?
      </template>

      <template #action>Eliminar</template>
    </Modal>
  </section>
</template>

<script>
// API
import DiscountAPI from '@/api/discount';

// Mixins
import DetailMixin from '@/mixins/DetailMixin';

export default {
  name: 'DiscountDetail',

  props: {
    uuid: {
      type: String,
      required: true,
    },
  },

  mixins: [DetailMixin],

  data: () => ({
    discount: {},
    listRouteName: 'discount-list',
  }),

  computed: {
    breadcrumbs() {
      const name = this.discount.code || '';
      return [
        {
          text: 'Descuentos',
          disabled: false,
          exact: true,
          to: { name: 'discount-list' },
        },
        {
          text: name.toUpperCase(),
          disabled: true,
        },
      ];
    },
  },

  async created() {
    this.api = new DiscountAPI();
    await this.detail();
  },

  methods: {
    async detail() {
      this.$store.commit('showLoader');
      try {
        this.discount = await this.api.detail(this.uuid);
      } catch (error) {
        this.$store.dispatch('notification/notifyError');
      }
      this.$store.commit('hideLoader');
    },
  },
};
</script>

<style lang="scss" scoped></style>
