// Components
import Modal from '@/components/modal/index.vue';

export default {
  components: {
    Modal,
  },

  data: () => ({
    listRouteName: '',
    showDeleteModal: false,
  }),

  methods: {
    async remove() {
      this.$store.commit('showLoader');
      try {
        await this.api.delete(this.uuid);
        this.$router.push({ name: this.listRouteName });
      } catch (error) {
        this.$store.dispatch('notification/notifyError');
      }
      this.$store.commit('hideLoader');
    },

    handleDeleteModal() {
      this.showDeleteModal = true;
    },
  },
};
